 const headerLinks = [
    {
        label: 'О законе',
        href: '#project'
    },
    {
        label: 'Подключение',
        href: '#services'
    },
    {
        label: 'Контакты',
        href: '#contact'
    },
    {
        label: 'Политика',
        href: '/files/policy.pdf',
        target:'_blank',
        rel:"noreferrer",
    },
    {
        label: 'Стоимость услуг',
        href: '/files/price.pdf',
        target:'_blank',
        rel:"noreferrer",
    },
] 

export default headerLinks